@import "/src/styles/index";

.side_nav {
  box-shadow: 0px 100px 80px rgba(205, 205, 205, 0.1),
    0px 41.7776px 33.4221px rgba(205, 205, 205, 0.0718854),
    0px 22.3363px 17.869px rgba(205, 205, 205, 0.0596107),
    0px 12.5216px 10.0172px rgba(205, 205, 205, 0.05),
    0px 6.6501px 5.32008px rgba(205, 205, 205, 0.0403893),
    0px 2.76726px 2.21381px rgba(205, 205, 205, 0.0281146);
  position: fixed;
  left: 0;
  top: 0;
  grid-column: 1 / span 1;
  background: #fff;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  :global {
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border: none;
    }
    .ant-tooltip-inner > a {
      color: #fff !important;
    }

    // .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    //   background-color: $dark-grey-color !important;
    // }

    .ant-layout-sider {
      background-color: #fff;
    }

    .ant-layout-sider-trigger {
      background-color: #2481df;
    }
    .ant-menu-item:after {
      left: 0;
      right: unset;
    }
    .ant-menu-item a {
      color: #b8b8b8;
    }
    .ant-menu {
      color: #b8b8b8;
    }
    .ant-menu-item-selected a {
      color: #1d0157;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: rgba(#1d0157, 0.2);
    }
    .ant-menu-item:hover a {
      color: #1d0157;
    }
    .ant-menu-submenu-arrow:after,
    .ant-menu-submenu-arrow:before {
      display: none;
    }
    .ant-menu-submenu-selected {
      background-color: rgba($primary-color, 0.1);
    }
    .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin-bottom: unset !important;
    }
    .ant-menu-inline .ant-menu-item {
      margin-top: unset !important;
    }

    // .ant-menu-item-selected.ant-menu-item-only-child::after {
    //   display: none;
    // }
    .ant-menu-submenu-selected .ant-menu .ant-menu-item-only-child {
      background: rgba($primary-color, 0.1);
    }
    .ant-menu-submenu-selected
      .ant-menu
      .ant-menu-item-only-child.ant-menu-item-selected {
      background: rgba($primary-color, 0.2);
    }
  }

  &__sider {
    flex-grow: 1 !important;
    // background: $dark-grey-color !important;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      height: 56px;
      border-radius: 8px;
      border: 4px solid transparent;
      background-clip: content-box;
      background-color: #b8b8b8;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #b8b8b8;
    }
  }
}

.logo_container {
  height: $header-height;
  line-height: $header-height;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
}
.showNotification {
  position: relative;
  &::before{
    position: absolute;
    display: block;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    top: 6px;
    right: 10px;
  }
}