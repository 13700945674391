@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700;900&display=swap");
@import "~antd/dist/antd.variable.min.css";
@import "./styles/index";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Noto Sans JP", sans-serif;
  overflow: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #d5d5d5;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #aeaeae;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}
.ant-btn {
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3) !important;
  }
}
